@use '~@chewy/kib-foundations/src/typography';
@use '~@chewy/kib-fields-new-styles/src/kib-field/styles' as kib-fields-new;
@use '~@chewy/kib-foundations/src/spacing';

.container {
  @include kib-fields-new.root-container;
}

.label {
  @include kib-fields-new.label;
}

.field {
  @include kib-fields-new.field;
}

.fieldIcon {
  @include kib-fields-new.icon;
}

.control {
  @include kib-fields-new.control;
  @include typography.style-as('form-text');
  height: calc(2 * spacing.get('s3') + spacing.get('s5')); // dbl padding + line height
  padding: 0;

  &:hover {
    @include kib-fields-new.control-on-hover;
  }
}

.controlError {
  @include kib-fields-new.control-on-invalid;
}

.error {
  @include kib-fields-new.error;
}

.errorMessage {
  @include kib-fields-new.error-message;
}

.errorIcon {
  @include kib-fields-new.error-icon;
}

.helpMessage {
  @include kib-fields-new.help;
}

.helpMessageBottom {
  @include kib-fields-new.help-bottom;
}
