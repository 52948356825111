@use '~@chewy/kib-fields-new-styles/src/kib-field/styles' as kib-fields-new;

// Required global styles (must load first)
// <Insert theme here if needed>
@import '~@chewy/kib-normalize/dist/kib-normalize.css';
@import '~@chewy/kib-normalize/src/theme/styles/theme-gordita';
// Chirp styles go here
@import '@chewy/kib-content-groups-styles/dist/styles/kib-accordion-new.css';
@import '@chewy/kib-content-groups-styles/dist/styles/kib-empty-state.css';
@import '@chewy/kib-carousels-styles/dist/styles/kib-carousel.css';
@import '@chewy/kib-controls-styles/dist/styles/kib-button-new.css';
@import '@chewy/kib-content-groups-styles/dist/styles/kib-section-heading.css';
@import '@chewy/kib-interstitials-styles/dist/styles/kib-spinner.css';
@import '@chewy/kib-layout-styles/dist/styles/kib-container.css';
@import '@chewy/kib-overlays-styles/dist/styles/kib-modal.css';
@import '@chewy/kib-product-styles/dist/styles/kib-product-card.css';
@import '@chewy/kib-skeleton-styles/dist/styles/kib-skeleton-shape.css';
@import '@chewy/kib-skeleton-styles/dist/styles/kib-skeleton-text.css';
@import '@chewy/kib-layout-styles/dist/styles/kib-breakpoint.css';
@import '@chewy/kib-layout-styles/dist/styles/kib-container.css';

// Chewy Payments Experience styles
@import '@chewy-payments-experience/credit-debit-card-form/src/credit-debit-card-form';

@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap');

html {
  font-size: 62.5%; /* 62.5% of 16px = 10px */
}

.braintree-hosted-fields-focused {
  @include kib-fields-new.control-on-focus;
}

.braintree-hosted-fields-invalid {
  @include kib-fields-new.control-on-invalid;
}
